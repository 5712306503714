<!-- Toolbar -->
<div class="toolbar" role="banner">
  <div class="toolbar-centred">

    <div class="toolbar-left-column">
      <a href="/#" [matTooltip]="logoDescription">
        <img
          alt="Logo"
          [src]="iconSource"
          i18n-alt
        />
      </a>
    </div>

    <div class="toolbar-center-column">
      <a href="/#">
        <h1 class="toolbar-title">{{title}}</h1>
      </a>
      <p class="subtitle">
        {{subtitle}}
      </p>
    </div>

    <div class="toolbar-right-column">

      <button
        mat-icon-button
        aria-label="Neue Frage"
        matTooltip="Neue Frage"
        (click)="resetChat()"
        i18n-matTooltip
        i18n-aria-label>
        <mat-icon>restart_alt</mat-icon>
      </button>

      <button
        class="hide-in-mobile"
        mat-icon-button
        aria-label="Einfache Sprache"
        matTooltip="Einfache Sprache"
        (click)="changeSimpleAnswer()"
        [ngClass]="{'shadow-enabled': easyLanguage}"
        i18n-matTooltip
        i18n-aria-label>
        <mat-icon>accessibility_new</mat-icon>
      </button>

      <ul class="navbar-nav">
        <ng-container>

          <div class="lang-switch-container">
            <button mat-button [matMenuTriggerFor]="menu" matTooltip="Sprache" i18n-matTooltip>
              <mat-icon [ngStyle]="{'color':'black'}">language</mat-icon>
              <mat-icon [ngStyle]="{'color':'black'}">arrow_drop_down</mat-icon>
            </button>
          </div>
          <mat-menu #menu="matMenu" xPosition="before">
            <a mat-menu-item (click)="changeLang(Language.German)" href="/de">Deutsch</a>
            <a mat-menu-item (click)="changeLang(Language.English)" href="/en">English</a>
            <a mat-menu-item (click)="changeLang(Language.French)" href="/fr">Français</a>
          </mat-menu>
        </ng-container>
      </ul>
    </div>

  </div>
</div>

<div class="content-container">
  <div class="content-padding">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>

</div>

