import { Component } from '@angular/core';
import {WhitelabelService} from "../../services/whitelabel.service";

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent {

  isBiblioSH = this.whiteLabelService.isBiblioSH

  constructor(private whiteLabelService: WhitelabelService) {
  }

}
