import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import { Language } from './interfaces/languages';
import {WhitelabelService} from "./services/whitelabel.service";
import {QuestionService} from "./services/question.service";
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = this.whitelabelService.title;
  subtitle = this.whitelabelService.subtitle;
  iconSource = this.whitelabelService.logoUrl
  logoDescription = this.whitelabelService.logoDescription

  get easyLanguage() {
    return this.questionService.easyLanguage;
  }

  set easyLanguage(val) {
    this.questionService.easyLanguage = val;
  }

  constructor(
    private whitelabelService: WhitelabelService,
    private questionService: QuestionService,
    private titleService: Title,
  ) {
    titleService.setTitle(this.whitelabelService.title + ' - ' + this.whitelabelService.subtitle)

    const getKeyByValue = (value: string): string | undefined =>
      (Object.keys(Language) as Array<keyof typeof Language>).find(key => Language[key] === value);

    //set language when the site is loaded in case it is not set yet
    const browserLanguage = navigator.language.split('-')[0]
    if (!localStorage.getItem("userLanguage")){
      let pageLanguage = browserLanguage in Object.keys(Language) ? Language[browserLanguage as keyof typeof Language] :  Language.German;
      this.changeLang(pageLanguage)
    }
    let userLanguage : string | null = window.location.pathname.replaceAll("\/", "") || localStorage.getItem("userLanguage") || navigator.language.split('-')[0];
    const keyByValueString = getKeyByValue(userLanguage)
    let pageLanguage: Language = keyByValueString ? Language[keyByValueString as keyof typeof Language] :  Language.German;
    this.changeLang(pageLanguage)

  }
  ngOnInit() {
  }
  handleSize(event : any) {
  }

  changeLang(userLanguage: Language){
    localStorage.setItem("userLanguage", userLanguage)
  }

  changeSimpleAnswer() {
    this.questionService.easyLanguage = !this.questionService.easyLanguage
  }

  async resetChat(){
    await this.questionService.resetChat()
    //const urlWithoutParams = window.location.pathname
    //window.location.assign(urlWithoutParams)
  }

  protected readonly Language = Language;
}


