import {EventEmitter, Injectable, signal, WritableSignal} from '@angular/core';
import {UserPromptResponse} from "../interfaces/user-prompt-response";
import {environment} from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {ErrorService} from "./error.service";
import {ChatMessage} from "../interfaces/chat_message";
import {ChatRole} from "../interfaces/ChatRole";
import {UserPromptResponseMeta} from "../interfaces/user-prompt-response-meta";
import {WhitelabelService} from "./whitelabel.service";

import * as Sentry from "@sentry/angular-ivy";
import {ActivatedRoute, Router} from "@angular/router";
@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  easyLanguage = false;

  latestResponseMeta: UserPromptResponseMeta = {
    client_request_uuid: "",
    server_request_uuid: "",
    document_references: [],
    search_only: false,
    is_completed: true,
    limit_reached: false
  };

  _latestResponse: ChatMessage = {
    content: "",
    role: ChatRole.ASSISTANT,
    meta: this.latestResponseMeta
  };


  defaultGreetingChat: ChatMessage = {
    content: this.whitelabelService.description,
    role: ChatRole.SYSTEM,
    meta: this.latestResponseMeta
  }

  _chatHistory: ChatMessage[] = [this.defaultGreetingChat]

  promptRequestSignal: WritableSignal<ChatMessage | undefined> = signal(undefined);
  chatHistorySignal: WritableSignal<ChatMessage[]> = signal(this._chatHistory);
  displayProgressBar: WritableSignal<boolean> = signal(false);
  newQuestion: EventEmitter<void> = new EventEmitter();
  ws?: WebSocket = undefined;

  private backendUrl: string = environment.backendUrl;
  private backendEndpoint: string = this.backendUrl + "/prompt";

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private whitelabelService: WhitelabelService,
    private router: Router,
  ) {
    this.reconnectWebSocket()
    //this.displayProgressBar.set(true);
  }

  private reconnectWebSocket() {
    if (this.ws == undefined || this.ws.readyState === this.ws.CLOSED || this.ws.readyState === this.ws.CLOSING) {
      let wsUrl = this.backendUrl.replace("http", "ws")
      if (!wsUrl.startsWith("ws")) {
        let webSocketProtocol = window.location.protocol == 'https:' ? 'wss' : 'ws'
        wsUrl = webSocketProtocol + "://" + window.location.host + wsUrl
      }
      try {
        let ws = new WebSocket(wsUrl + "/ws")
        this.ws = ws;
        ws.onclose = (event) => {
          console.warn("Websocket did disconnect: ", event.reason)
          this.ws = undefined;
        }
      } catch (error) {
        if (error instanceof Error) {
          this.errorService.logError(error)
        }
      }
    }
  }

  async resetChat() {
    this._chatHistory = [this.defaultGreetingChat]
    this.chatHistorySignal.set(this._chatHistory);
    if (this.router.routerState.snapshot.url !== '/') {
      await this.router.navigate(['/'])
    }
  }

  async makeServerCall(prompt : ChatMessage){
    // let the feedback component know that there was a new question so that it can prepare to take new feedback
    this.newQuestion.emit();

    this.promptRequestSignal.set(prompt);
    this._chatHistory.push(prompt)

    //display progress bar while the answer is fetched
    this.displayProgressBar.set(true);


    let userPromptResponseMeta: UserPromptResponseMeta = {
      client_request_uuid: "",
      document_references: [],
      search_only: this.promptRequestSignal()?.meta['search_only'],
      is_completed: false,
      limit_reached: false
    }

    this.reconnectWebSocket()

    // wait for the websocket to be connected
    if (this.ws && this.ws.readyState !== this.ws.OPEN) {
      let awaitConnectStartTime = new Date().valueOf()
      let existingWebSocket = this.ws;
      let promise = new Promise<void>((resolve, reject) => {
        let timeout = setTimeout(() => {
          resolve();
        }, 5 * 1000)
        existingWebSocket.onopen = () => {
          clearTimeout(timeout)
          let awaitConnectStopTime = new Date().valueOf()
          let duration = awaitConnectStopTime - awaitConnectStartTime
          console.log("Websocket connected after " + (duration / 1000).toFixed(3) + "s")
          resolve()
        }
      })
      await promise;
    }

    let startTime = new Date().valueOf()
    let duration = -1

    if (this.ws != undefined) {

      let waitForResponseTimeout = setTimeout(() => {
        this.errorService.showError($localize`Leider konnte der Server diese Anfrage aktuell nicht beantworten. Bitte versuche es später noch einmal.`, new Error("Response timeout"))
        this.displayProgressBar.set(false);
      }, 60 * 1000)

      this.ws.onmessage = (event) => {

        let incomingChat = JSON.parse(event.data)
        let responseMeta = incomingChat.meta

        if (responseMeta.client_request_uuid === prompt.meta["client_request_uuid"]) {

          clearTimeout(waitForResponseTimeout)

          if (responseMeta.limit_reached) {
            console.warn("Limit reached for request " + responseMeta.client_request_uuid)
            this.errorService.openErrorDialog($localize`Leider ist ihr Kontingent aktuell ausgeschöpft. Bitte versuche es später noch einmal.`, undefined)
            this.displayProgressBar.set(false);

          }
          else { // process the chat message and store correctly

            if (responseMeta.is_completed) {
              let endTime = new Date().valueOf()
              duration = endTime - startTime
              console.log("Duration for request " + responseMeta.client_request_uuid + ": ", (duration / 1000).toFixed(3) + "s")
            }

            userPromptResponseMeta = {
                client_request_uuid: responseMeta.client_request_uuid,
                server_request_uuid: responseMeta.server_request_uuid,
                document_references: responseMeta.document_references,
                search_only: responseMeta.search_only,
                is_completed: responseMeta.is_completed,
                limit_reached: responseMeta.limit_reached
              };

            let latestChunk : ChatMessage = {
              content: incomingChat.content,
              role: incomingChat.role,
              meta: userPromptResponseMeta
            }

            this._chatHistory.push(latestChunk)
            this.chatHistorySignal.set(this._chatHistory); //set signal; this change is detected in the answer-display component
            this.displayProgressBar.set(false); //stop progress bar when answer is fetched
            this.newQuestion.emit(); // let the feedback component know that there was a new question so that it can prepare to take new feedback
          }
        }
        else {
          console.log('Something went wrong, the UUIDs don\'t match. Expected: '+ responseMeta.client_request_uuid + " received instead: " + prompt.meta["client_request_uuid"])
        }
      this._latestResponse = this._chatHistory[this._chatHistory.length - 1]
      }

      try {
        this.ws.send(JSON.stringify(this._chatHistory))
      }
      catch (error) {
        if (error instanceof Error) {
          this.errorService.showError($localize`Leider konnte der Server diese Anfrage aktuell nicht beantworten. Bitte versuche es später noch einmal.`, error)
        }
        this.displayProgressBar.set(false);

      }
    } else {
      this.errorService.showError($localize`Leider konnte der Server diese Anfrage aktuell nicht beantworten. Bitte versuche es später noch einmal.`, new Error("Websocket not connected"))
      this.displayProgressBar.set(false);
    }
  }

  getUserPrompt() : string {
    let currentPrompt = this.promptRequestSignal();
    return currentPrompt ? currentPrompt.content : "";
  }
}
