<h1 mat-dialog-title i18n>Chat Einstellungen</h1>
<div mat-dialog-content class="settings-panel">

  <mat-form-field>
    <mat-label>Sprachmodel (LLM)</mat-label>
    <mat-select (selectionChange)="storeChosenValue($event)" [value]="defaultGPTVersion">
      <mat-option [value]="option" *ngFor="let option of gptVersionOptions">{{option}}</mat-option>
    </mat-select>
  </mat-form-field>


  <div class="toggle-container">
    <mat-slide-toggle class="slide-toggle"[(ngModel)]="data.searchOnly" (click)="$event.stopPropagation()" i18n>Suchmodus (ohne
      GPT Antwort)
    </mat-slide-toggle>
    <mat-slide-toggle class="slide-toggle"[(ngModel)]="data.easyLanguage" (click)="$event.stopPropagation()" i18n>Antworte in einfacher Sprache
    </mat-slide-toggle>
    <mat-slide-toggle class="slide-toggle"[(ngModel)]="data.recentnessRankingRequested" (click)="$event.stopPropagation()" i18n>
      Neuere Quellen bevorzugen
    </mat-slide-toggle>
    <mat-form-field id="recentnessRankingToggleArea" *ngIf="data.recentnessRankingRequested"> <!-- && !environment.production" -->
      <mat-label id="recentnessRankingNumberLabel">Anzahl zu berücksichtigende Dokumente</mat-label>
      <input id="recentnessRankingNumberInput" type="number" step="1" min="1" max="500" matInput [(ngModel)]="data.top_k_for_retrieval">
    </mat-form-field>
  </div>



  <button class="dialog-button" mat-button (click)="toggleSourceFilter()">
    <div class="settings-buttons-container">
      <mat-icon class="mat-filter-icon">filter_list</mat-icon>
      <div class="button-text" i18n>Quellen Filtern</div>
      <mat-icon *ngIf="!showSourceFilter">keyboard_arrow_down</mat-icon><mat-icon *ngIf="showSourceFilter">keyboard_arrow_up</mat-icon>
    </div>
  </button>

  <div *ngIf="showSourceFilter">
    <span class="check-box-list-section">
      <mat-checkbox class="check-box-list-margin"
                    [checked]="allComplete"
                    [color]="source.color"
                    [indeterminate]=  someComplete()
                    (change)="setAll($event.checked)">
        Alle Quellen auswählen / abwählen
      </mat-checkbox>
    </span>
    <span class="check-box-list-section">
      <ul>
        <li *ngFor="let subsource of sourceList">
          <mat-checkbox [(ngModel)]="subsource.completed"
                        [color]="subsource.color"
                        (ngModelChange)="updateAllComplete()">
            {{subsource.userDisplayName}}
          </mat-checkbox>
        </li>
      </ul>
    </span>
  </div>
</div>


<div mat-dialog-actions class="dialog-button-container">
  <button class="dialog-button" mat-button [mat-dialog-close]="data" cdkFocusInitial i18n>Schliessen</button>
</div>

