<div class="waves">

</div>
<div class="footer">
  <p class="footer-text">
    © 2024 <a href="https://www.appmanufacture.ch/" target="_blank">App Manufacture GmbH</a>
    -
    <a routerLink="imprint">Impressum</a>
    -
    <a routerLink="privacy-policy">Datenschutzerklärung</a>
  </p>
  <p class="footer-text" *ngIf="disclaimer" [innerHTML]="disclaimer"></p>
</div>
