
<div class="suggested-queries" *ngIf="chatHistory.length == 1">
  <button mat-raised-button *ngFor="let element of suggestedQueries" (click)="onSuggestionClick(element)">{{element}}</button>
</div>

<div class="new-question" *ngIf="chatHistory.length > 1 && false">
  <a mat-raised-button (click)="nextQuestion()">{{nextQuestionButtonText}}</a>
</div>


<div class="input-field-container horizontal-center">

  <div class="input-field-and-settings">
    <mat-form-field class="input-form-field" color="accent" appearance="outline">
      <mat-label *ngIf="chatHistory.length <= 1" i18n>Eine Frage stellen</mat-label>
      <mat-label *ngIf="chatHistory.length > 1" i18n>Möchten Sie noch mehr dazu wissen?</mat-label>
      <div class="input-with-buttons">
        <textarea matInput
                  cdkTextareaAutosize
                  autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="10"
                  [(ngModel)]="userQuestion"
                  autofocus
                  autocomplete="off"
                  (keydown.enter)="onEnterKey($event)"
        >
        </textarea>
        <div class="button-container">
          <button *ngIf="userQuestion" class="hide-in-mobile" mat-icon-button aria-label="Clear" (click)="resetQuestion()">
            <mat-icon>close</mat-icon>
          </button>

          <button mat-icon-button (click)="makeServerCall()" matTooltip="Senden" i18n-matTooltip
                  [disabled]="displayProgressBar && !allowParallelRequests">
            <mat-icon color="accent">send</mat-icon>
          </button>
          <button mat-icon-button (click)="openDialog()" matTooltip="Mehr Optionen" i18n-matTooltip>
            <mat-icon>tune</mat-icon>
          </button>
        </div>
      </div>
      </mat-form-field>
    </div>
  </div>
