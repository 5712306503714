import {RouterModule, Routes} from '@angular/router';
import { Language } from './interfaces/languages';
import {NgModule} from '@angular/core';
import {PrivacyPolicyComponent} from "./components/privacy-policy/privacy-policy.component";
import {ImprintComponent} from "./components/imprint/imprint.component";
import {ChatComponent} from "./components/chat/chat.component";

const userLanguageAsString : string = localStorage.getItem("userLanguage") || window.navigator.language.split('/')[0];
const userLanguage: Language = Language[userLanguageAsString as keyof typeof Language] || Language.German

const routes: Routes = [
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'imprint', component: ImprintComponent },
  {path: '', component: ChatComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
