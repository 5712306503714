<div [ngClass]="{'feedback-container': !isGeneralFeedback, 'general-feedback-container': isGeneralFeedback}">

  <div [ngClass]="{'feedback-accordion': !isGeneralFeedback, 'general-feedback-accordion': isGeneralFeedback}">

    <div class="rating-area">
            <span class="rating-icon" (click)="sendShortFeedback(1)">
              <mat-icon class="rating-success" [class.selected]="rating.value === 1">thumb_up</mat-icon>
            </span>
            <span class="rating-icon" (click)="sendShortFeedback(0)">
              <mat-icon class="rating-failure" [class.selected]="rating.value === 0">thumb_down</mat-icon>
            </span>
            <span *ngIf="showFeedbackAccordion.includes(messageIndex) && !isGeneralFeedback" class="rating-icon" (click)="hideFeedbackAccordion()">
              <mat-icon class="close-accordion">close</mat-icon>
            </span>
    </div>

    <div class="feedback-form-container" *ngIf="answerHasBeenRated.includes(messageIndex) && this.showFeedbackAccordion.includes(messageIndex) && !extendedFeedbackGiven.includes(messageIndex) || isGeneralFeedback">
      <form class="feedback-form" [formGroup]="feedbackForm" (ngSubmit)="sendExtendedFeedback()">
        <ng-container>

          <mat-form-field *ngIf="!isGeneralFeedback && negativeRatings.includes(messageIndex)">
            <mat-label i18n>Weshalb sind Sie mit der Antwort unzufrieden?</mat-label>
            <mat-select placeholder="" formControlName="dropdown">
              <mat-option value="wrong" i18n>Die Anwort war falsch.</mat-option>
              <mat-option value="unhelpful" i18n>Die Antwort war nicht hilfreich.</mat-option>
              <mat-option value="inappropriate" i18n>Die Antwort war unangebracht.</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field >
            <input matInput type="text" placeholder="Ihre Rückmeldung" i18n-placeholder formControlName="feedback">
          </mat-form-field>

          <mat-form-field>
            <input matInput type="email" placeholder="Emailadresse (optional)" i18n-placeholder formControlName="email">
            <mat-error *ngIf="feedbackForm.get('email')?.hasError('invalid')"></mat-error>
          </mat-form-field>

          <br/>
          <button type="submit" mat-raised-button color="primary" i18n>Rückmeldung senden</button>
        </ng-container>
      </form>
    </div>
  </div>
</div>
