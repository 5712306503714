import { Component } from '@angular/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent {

  rating: { value: number } = {value: -2};

  setRating(newRating : number){
    this.rating.value = newRating
  }

}
