<h1>Impressum</h1>

<ng-container *ngIf="isBiblioSH">

  <h4>Über SHAI</h4>
  <p>SHAI ist ein Projekt der <a
    href="https://bibliotheken-schaffhausen.ch/de/" target="_blank">Bibliotheken
    Schaffhausen</a> im Rahmen der <a href="https://smartcity.stsh.ch/" target="_blank">
    Smart-City Strategie der Stadt Schaffhausen</a>. Zusammen mit
    dem Partner <a href="https://www.appmanufacture.ch/de/" target="_blank">App
      Manufacture</a> wurde eine KI-unterlegte Recherche-Assistenz auf der Basis
    von ChatGPT entwickelt. Sie soll unkompliziert und kompetent Auskunft zur
    Bibliothek und ihrem Angebot geben. Darüber hinaus kann SHAI auch Antworten zur
    Schaffhauser Kultur und Geschichte liefern.</p>

  <p>Bibliotheken sind wichtige Lieferantinnen für qualitativ
    hochwertige Informationen. Manchmal ist es aber nicht so einfach, sich im
    Umfeld von bibliothekarischen Katalogen, Portalen und Websites zurechtzufinden.
    Mit SHAI versuchen die Bibliotheken Schaffhausen einen neuen Zugang zu ihrem
    Angebot zu schaffen, der auch neue und weniger geübte Nutzerinnen und Nutzer
    erreicht. Deshalb spricht SHAI auch mehrere Sprachen und kann Informationen in
    einfacher Sprache darstellen.</p>

  <p>Die SHAI zugrunde liegenden &quot;Large Language
    Models&quot; funktionieren umso besser, je kompakter und einheitlicher die
    Daten sind, die sie auswerten. Für SHAI kommt nur ein begrenzter und qualitativ
    hochwertiger Datenbestand zum Einsatz: Informationen der Bibliothekswebsite und
    des Bibliothekskatalogs sowie der Wikipedia und des Historischen Lexikons der
    Schweiz (HLS). Trotzdem können Fehler und Ungenauigkeiten auftreten. Die
    Technologie ist noch neu - es ist daher auch ein erklärtes Ziel des Projekts,
    der Schaffhauser Bevölkerung Gelegenheit zum Ausprobieren der neuen Technologie
    &quot;Künstliche Intelligenz&quot; zu geben. <a
      href="mailto:bibliothek@stsh.ch" target="_blank">Feedback</a> und Reflexion sind daher
    wichtige Elemente für die Weiterentwicklung von SHAI.</p>

  <mat-divider></mat-divider>

  <h4> Kontakt</h4>
  <p>
    Stadtbibliothek Schaffhausen<br/>
    Münsterplatz 1<br/>
    8200 Schaffhausen<br/>
    T 052 632 50 20<br/>
    F 052 632 50 39<br/>
    bibliothek(at)stsh.ch<br/>
  </p>

  <mat-divider></mat-divider>

</ng-container>


<h4> Technische Umsetzung</h4>

<p>
  App Manufacture GmbH<br>Vordergasse 34<br>CH-8200 Schaffhausen<br>Schweiz
</p>
<p>
  <a href="https://www.appmanufacture.ch" target="_blank">www.appmanufacture.ch</a>
</p>

<mat-divider></mat-divider>

<h4> Haftungsausschluss</h4>

<p>
  Der Betreiber dieser Lösung übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.
  Haftungsansprüche gegen den Betreiber wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch
  der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen. Alle Angebote sind unverbindlich.
  Der Betreiber behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
</p>

<mat-divider></mat-divider>

<h4> Haftung für Links</h4>

<p>
  Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird jegliche Verantwortung für solche Webseiten abgelehnt.
  Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.
</p>
