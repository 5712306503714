import {
  Component,
  effect, Inject,
  Input,
  OnInit
} from '@angular/core';
import {QuestionService} from "../../services/question.service";
import {ActivatedRoute} from "@angular/router";
import {v4 as uuidv4} from "uuid";
import {Language} from "../../interfaces/languages";
import {Location, NgForOf, NgIf} from '@angular/common';
import {gptVersion} from "../../interfaces/gptVersions";
import {environment} from 'src/environments/environment';
import {WhitelabelService} from "../../services/whitelabel.service";
import {ChatMessage} from "../../interfaces/chat_message";
import {ChatRole} from "../../interfaces/ChatRole";
import {UserPromptRequestMeta} from "../../interfaces/user-prompt-request-meta";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule, MatDialogRef,
} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatExpansionModule} from "@angular/material/expansion";
import {SourceEntity} from "../../interfaces/sourceEntity";
import {SettingsDialogData} from "../../interfaces/settingsDialogData";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectChange, MatSelectModule} from "@angular/material/select";


@Component({
  selector: 'question-input',
  templateUrl: './question-input.component.html',
  styleUrls: ['./question-input.component.scss']
})
export class QuestionInputComponent implements OnInit {

  allowParallelRequests = false

  userQuestion = '';
  displayProgressBar = false;
  chatHistory: ChatMessage[] = []

  searchOnly = false;
  chosenGPTVersion = this.whitelabelService.defaultGPTVersion
  recentnessRankingRequested = this.whitelabelService.recentnessRankingRequestedByDefault;
  top_k_for_retrieval: number = 100;
  selectedSources: string[] = this.whitelabelService.sources
  suggestedQueries: string[] = this.whitelabelService.suggestedQueries


  nextQuestionButtonText : string = this.whitelabelService.nextQuestion
  environment = environment

  get easyLanguage() {
    return this.questionService.easyLanguage;
  }

  set easyLanguage(val) {
    this.questionService.easyLanguage = val;
  }

  @Input() setRating!: (newRating: number) => void;
  @Input() rating!: { value: number };

  callResetRating() {
    this.setRating(-1)
  }

  constructor(
    private questionService :  QuestionService,
    private route: ActivatedRoute,
    private location: Location,
    private whitelabelService: WhitelabelService,
    public dialog: MatDialog,
  ) {
    effect(() => { //update signals from questionService when their values change
      this.displayProgressBar = this.questionService.displayProgressBar();
      this.chatHistory = this.questionService.chatHistorySignal();
    })
  }


  openDialog(): void {
      const dialogRef = this.dialog.open(ChatSettingsDialog, {
        data : {
          selectedSources: this.selectedSources,
          chosenGPTVersion: this.chosenGPTVersion,
          searchOnly: this.searchOnly,
          easyLanguage: this.easyLanguage,
          recentnessRankingRequested: this.recentnessRankingRequested,
          top_k_for_retrieval: this.top_k_for_retrieval,
        }
      });

      dialogRef.backdropClick().subscribe(() => {
        dialogRef.close(dialogRef.componentInstance.data); // Make sure to update this according to how you manage data changes
        });

      dialogRef.afterClosed().subscribe(result => {

        this.selectedSources = result.selectedSources ?? this.selectedSources;
        this.chosenGPTVersion = result.chosenGPTVersion ?? this.chosenGPTVersion;
        this.searchOnly = result.searchOnly ?? this.searchOnly;
        this.easyLanguage = result.easyLanguage ?? this.easyLanguage;
        this.recentnessRankingRequested = result.recentnessRankingRequested ?? this.recentnessRankingRequested;
        this.top_k_for_retrieval = result.top_k_for_retrieval ?? this.top_k_for_retrieval;

      });
    }


  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      const allowParallelRequestsString = params['allowParallelRequests']
      if (allowParallelRequestsString != null) {
        this.allowParallelRequests = allowParallelRequestsString.toLowerCase() == 'true';
      }


      const autoQueryString = params['a'] ? decodeURI(params['a']) : 'true'
      const autoQuery = autoQueryString && autoQueryString == 'true' || autoQueryString == '1'
      const query = params['q']

      if (query && autoQuery){
        this.userQuestion = decodeURI(query)
        if (this.userQuestion.length > 0) {
          this.makeServerCall().catch(e => {
            console.error(e)
          })
        }
      }
    })
  }

  onSuggestionClick(suggestion: string){

    if (this.displayProgressBar) {
      return
    }

    this.userQuestion = suggestion;
    this.makeServerCall().catch(e => {
        console.error(e)
      }
    )
  }


  onEnterKey(event: Event): void {
    event.preventDefault()
    if (this.userQuestion.length > 0) {
      this.makeServerCall().catch(e => {
          console.error(e)
        }
      )
    }
  }

  async makeServerCall(){

    if (this.displayProgressBar && !this.allowParallelRequests) {
      // prevent multiple calls
      return
    }

    if (!this.userQuestion) {
      return
    }

    this.location.replaceState('?q='+ encodeURI(this.userQuestion)+'&a=false');
    //this.location.replaceState('?q='+ encodeURI('dialog'));

    this.callResetRating()

    const getKeyByValue = (value: string): string | undefined =>
      (Object.keys(Language) as Array<keyof typeof Language>).find(key => Language[key] === value);

    const userLanguageAsString : string = localStorage.getItem("userLanguage") ||'de';
    const keyByValueString = getKeyByValue(userLanguageAsString)
    const userLanguage: Language |undefined = keyByValueString ? Language[keyByValueString as keyof typeof Language] :  Language.German;


    const promptMeta: UserPromptRequestMeta = {
      client_request_uuid: uuidv4(),
      search_only: this.searchOnly,
      language : userLanguage,
      gpt_version : this.chosenGPTVersion,
      easy_language: this.questionService.easyLanguage,
      recentness_ranking_requested: this.recentnessRankingRequested,
      source_entity_filter: this.selectedSources,
      top_k_for_retrieval: this.top_k_for_retrieval
    }
    let newPrompt: ChatMessage ={
      content: this.userQuestion,
      role: ChatRole.USER,
      meta: promptMeta
    }
    console.log(prompt)
    this.resetQuestion()
    await this.questionService.makeServerCall(newPrompt);
  }

  resetQuestion() {
    this.userQuestion = ''
  }


  async nextQuestion(){
    const urlWithoutParams = window.location.pathname
    window.location.assign(urlWithoutParams)
  }

}



@Component({
  selector: 'question-input-dialog',
  templateUrl: 'question-input-dialog.html',
  styleUrls: ['./question-input.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatInputModule, FormsModule, MatSlideToggleModule, MatButtonToggleModule, MatCheckboxModule, MatExpansionModule, NgForOf, MatIconModule, NgIf, MatSelectModule],
})
export class ChatSettingsDialog implements OnInit{

  protected readonly gptVersion = gptVersion;
  gptVersionOptions = Object.values(gptVersion);

  whiteLabelService : WhitelabelService = new WhitelabelService();
  defaultGPTVersion;
  selectedSources = this.data.selectedSources
  allComplete : any;
  source: SourceEntity ={color: undefined, completed: false, name: "", userDisplayName: ''}
  showSourceFilter:boolean = false;


  sourceList: SourceEntity[] = this.data.selectedSources.map(name => ({
    name,
    completed: false,
    color: 'primary',
    userDisplayName: this.whiteLabelService.sourceNameForUsers(name)
  }));


  constructor(
    public dialogRef: MatDialogRef<ChatSettingsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: SettingsDialogData,) {

    this.defaultGPTVersion = this.data.chosenGPTVersion
  }

  ngOnInit(): void {

    this.sourceList = this.whiteLabelService.sources.map(name => ({
      name,
      completed: this.selectedSources.includes(name),
      color: 'primary',
      userDisplayName: this.whiteLabelService.sourceNameForUsers(name)
    }));

    this.updateAllComplete()

  }

  updateAllComplete() {
    this.allComplete = this.sourceList != null && this.sourceList.every(t=> t.completed);
    this.data.selectedSources = this.sourceList.filter(t => t.completed).map(source => source.name)
  }

  someComplete(): boolean {
    if (this.selectedSources == null) {
      return false;
    }
    this.selectedSources = this.sourceList.filter(t => t.completed).map(source => source.name);
    return this.sourceList.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.selectedSources == null) {
      return;
    }
    this.sourceList.forEach(t => (t.completed = completed));
    this.data.selectedSources = this.sourceList.filter(t => t.completed).map(source => source.name)
  }

  toggleSourceFilter() {
    this.showSourceFilter = !this.showSourceFilter
  }

  storeChosenValue(event: MatSelectChange){
    this.data.chosenGPTVersion = event.value
  }

}
