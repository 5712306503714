import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatGridListModule} from "@angular/material/grid-list";
import { ResponsiveGridDemoComponent } from './components/responsive-grid-demo/responsive-grid-demo.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatCardModule} from "@angular/material/card";
import { QuestionInputComponent } from './components/question-input/question-input.component';
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { AnswerDisplayComponent } from './components/answer-display/answer-display.component';
import {NgOptimizedImage} from "@angular/common";
import { FinePrintComponent } from './components/fine-print/fine-print.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatListModule} from "@angular/material/list";
import { FeedbackComponent } from './components/feedback/feedback.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import {MatSelectModule} from "@angular/material/select";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatTableModule} from "@angular/material/table";
import { HttpClientModule } from '@angular/common/http';
import { PrivacyHintHallucinationDisclaimerComponent } from './components/privacy-hint-hallucination-disclaimer/privacy-hint-hallucination-disclaimer.component';
import {MatRadioModule} from "@angular/material/radio";
import { FooterComponent } from './components/footer/footer.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatMenuModule} from "@angular/material/menu";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { ImprintComponent } from './components/imprint/imprint.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ChatComponent } from './components/chat/chat.component';

@NgModule({
  declarations: [
    AppComponent,
    ResponsiveGridDemoComponent,
    QuestionInputComponent,
    AnswerDisplayComponent,
    FinePrintComponent,
    FeedbackComponent,
    PrivacyHintHallucinationDisclaimerComponent,
    FooterComponent,
    ErrorDialogComponent,
    ImprintComponent,
    PrivacyPolicyComponent,
    ChatComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatGridListModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    NgOptimizedImage,
    MatExpansionModule,
    MatListModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatRadioModule,
    MatDialogModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatCheckboxModule,
  ],

  providers: [{
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: true,
    }),
  }, {
    provide: Sentry.TraceService,
    deps: [Router],
  },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
